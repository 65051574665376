import React from 'react'
import { graphql } from "gatsby"
import { PageProps } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import Layout from '../components/Layout'
import * as styles from './News.module.scss'
import { formatDate, sortByDateProperty, transformStaticPath } from '../utils'
import Button from '../components/Button'
import { useIntl } from "gatsby-plugin-intl"

type NewsQueryProps = PageQuery<{
  hero: HeroImageData<IGatsbyImageData>;
  title: string;
  body: MarkdownField;
  news: {
    date: string;
    title: string;
    image: IGatsbyImageData;
    text: MarkdownField;
    pdf: string;
  }[];
}>

const NewsPage = ({ data }: PageProps<NewsQueryProps>) => {
  const { locale, formatMessage } = useIntl()
  const { markdownRemark } = data
  const frontmatter = markdownRemark.frontmatter[locale]!

  return (
    <Layout
      seo={{title: frontmatter.title, description: frontmatter.body.html}}
      hero={frontmatter.hero}
      introCard={{
        title: frontmatter.title,
        body: frontmatter.body.html
      }}
      wide
    >
      {sortByDateProperty(frontmatter.news, 'date').map((newsItem, index) => {
        const newsImage = newsItem.image && getImage(newsItem.image)
        const pdfPath = transformStaticPath(newsItem.pdf)

        return (
          <div key={index} className={styles.card}>
            <div className={styles.cardContent}>
              {newsImage && (
                <a href={pdfPath} target='_blank'>
                  <GatsbyImage
                    image={newsImage}
                    alt={newsItem.title}
                    className={styles.image}
                  />
                </a>
              )}
              <div className={styles.textContent}>
                <div className={styles.date}>{formatDate(new Date(newsItem.date))}</div>
                <div className={styles.title}>{newsItem.title}</div>
                <div dangerouslySetInnerHTML={{__html: newsItem.text.html}}></div>
              </div>
            </div>
            <Button openInNewTab href={pdfPath} icon='info' noMarginBottom align='flex-end'>{formatMessage({id: 'courses.moreInformation'})}</Button>
          </div>
        )
      })}
    </Layout>
  )
}

export const pageQuery = graphql`
  fragment NewsFragment on FrontmatterLocalized {
    hero {
      image {
        desktopHero: childImageSharp {
          gatsbyImageData(
            width: 1920
            aspectRatio: 2.5
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
          )
        }
        mobileHero: childImageSharp {
          gatsbyImageData(
            width: 760
            aspectRatio: 1.5
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
          )
        }
      }
    }
    title
    body {
      html
    }
    news {
      date
      title
      image {
        childImageSharp {
          gatsbyImageData(
            width: 320
            aspectRatio: 1.5
            placeholder: BLURRED
          )
        }
      }
      text {
        html
      }
      pdf
    }
  }

  query NewsPage {
    markdownRemark(fileAbsolutePath: {regex: "/pages\/news.md/"}) {
      frontmatter {
        de {
          ...NewsFragment
        }
        en {
          ...NewsFragment
        }
      }
    }
  }
`

export default NewsPage
